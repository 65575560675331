var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemData
        ? _c(
            "b-row",
            { staticClass: "content-header" },
            [
              _c(
                "b-col",
                {
                  staticClass: "content-header-left mb-2",
                  attrs: { cols: "12", md: "9" }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "breadcrumbs-top" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "content-header-title float-left pr-1 mb-0"
                          },
                          [_vm._v(" Post Orders ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "breadcrumb-wrapper" },
                          [
                            _c(
                              "b-breadcrumb",
                              [
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/reports/type" } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "align-text-top",
                                      attrs: { icon: "HomeIcon", size: "16" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/postorders/list/" } },
                                  [_vm._v(" Post Orders ")]
                                ),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { active: "" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.itemData.title) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.itemData &&
      (_vm.$can("read", _vm.abilityRequired) ||
        _vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _vm.$can("create", _vm.abilityRequired) ||
              _vm.$can("update", _vm.abilityRequired) ||
              _vm.$can("delete", _vm.abilityRequired)
                ? _c(
                    "b-card-body",
                    [
                      _c("validation-observer", {
                        ref: "observer",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var handleSubmit = ref.handleSubmit
                                return [
                                  _c(
                                    "b-form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return handleSubmit(_vm.validateForm)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Title ",
                                                    "label-for": "title"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: { name: "title" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "title"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .title,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "title",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.title"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.itemSK === null &&
                                      (_vm.$can(
                                        "create",
                                        _vm.abilityRequired
                                      ) ||
                                        _vm.$can("update", _vm.abilityRequired))
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "b-row",
                                                { staticClass: "mt-3" },
                                                [
                                                  _vm.$can(
                                                    "create",
                                                    _vm.abilityRequired
                                                  )
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "3" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                type: "submit",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Add " +
                                                                  _vm._s(
                                                                    _vm.itemTypeName
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.itemSK !== null &&
                                      (_vm.$can(
                                        "create",
                                        _vm.abilityRequired
                                      ) ||
                                        _vm.$can(
                                          "update",
                                          _vm.abilityRequired
                                        ) ||
                                        _vm.$can("delete", _vm.abilityRequired))
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "b-row",
                                                { staticClass: "mt-3" },
                                                [
                                                  _vm.$can(
                                                    "update",
                                                    _vm.abilityRequired
                                                  )
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                type: "submit",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Save Changes "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.$can(
                                                    "delete",
                                                    _vm.abilityRequired
                                                  )
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.deleteItem
                                                              }
                                                            },
                                                            [_vm._v(" Delete ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2164462379
                        )
                      }),
                      _c("div", [
                        _vm._v("Created: " + _vm._s(_vm.itemData.creation_date))
                      ])
                    ],
                    1
                  )
                : _vm.$can("read", _vm.abilityRequired)
                ? _c("b-card-body", [
                    _c("div", [_vm._v(_vm._s(_vm.itemData.title))]),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v(
                        " Created: " + _vm._s(_vm.itemData.creation_date) + " "
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.itemData &&
      _vm.itemSK !== null &&
      _vm.maxFiles > 0 &&
      (_vm.$can("read", _vm.abilityRequired) ||
        _vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Files ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.$can("create", _vm.abilityRequired) &&
                        _vm.$can("create", _vm.abilityRequired)
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openFileSelector }
                                  },
                                  [_vm._v(" Add File ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.itemData.attachment
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      [
                        _c(
                          "b-card",
                          [
                            _vm.itemData.attachment &&
                            _vm.itemData.attachment.access_url &&
                            _vm.itemData.attachment.filename
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _vm.$can(
                                              "read",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      block: "",
                                                      disabled:
                                                        _vm.preventDoubleClick
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.downloadFile(
                                                          _vm.itemData
                                                            .attachment
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Download File ")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm.$can("update", _vm.abilityRequired)
                                          ? _c(
                                              "b-col",
                                              [
                                                _vm.$can(
                                                  "delete",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteFile(
                                                              _vm.itemData
                                                                .attachment,
                                                              true
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Delete File ")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "*/*" },
                  on: { change: _vm.handleFileChange }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }